<template>
    <div>
        <AppPaginatedGrid :loadingEffect="loadingEffect" ref="grid" permission="estrutura_unidade"
            :customDelete="customDelete" :service="service" newPath="/branch/new" editPath="/branch/" :expander="true"
            subtitle="Unidade" tooltip="Cadastro unidades" nomeTelaDoManual="branch-list"
            origemHistoricoAcao="/customer/branches">
            <template #columns>
                <Column field="id" :sortable="true" sortField="customer_branches.id" header="Cod."></Column>
                <Column field="cliente.name" :sortable="true" sortField="cliente.name" header="Cliente"></Column>
                <Column field="customer_branches.name" :sortable="true" sortField="customer_branches.name"
                    header="Nome">
                    <template #body="slotProps">
                        {{ slotProps.data.name }}
                        <small class="block text-500">{{ slotProps.data.tradeName }}</small>
                    </template>
                </Column>
                <Column field="tradeCode" :sortable="true" sortField="customer_branches.tradeCode" header="Inscrição">
                    <template #body="{ data }">
                        {{ getFormatacaoCnpj(data.tradeCode) }}
                    </template>
                </Column>
                <Column field="created_at" :sortable="true" sortField="customer_branches.created_at" header="Criado">
                    <template #body="slotProps">
                        {{ $filters.formatDate(slotProps.data.createdAt) }}
                        <small class="block text-500"> por {{ slotProps.data.userCreate?.name }} </small>
                    </template>
                </Column>
                <Column field="updated_at" :sortable="true" sortField="customer_branches.updated_at"
                    header="Atualizado">
                    <template #body="slotProps">
                        {{ $filters.formatDate(slotProps.data.updatedAt) }}
                        <small v-if="slotProps.data.userUpdate?.name" class="block text-500"> por {{
            slotProps.data.userUpdate?.name }} </small>
                    </template>
                </Column>
                <Column header="Codigo SOC" :sortable="true">
                    <template #body="{ data }">
                        <div v-if="!data.cliente?.integracaoSoc" class="field mt-1 mb-0" style="text-align: center">
                            {{ 'Sem integração' }}
                        </div>
                        <div v-if="data.cliente?.integracaoSoc && !data.codigoSoc" class="field mt-1 mb-0"
                            style="text-align: center">
                            <i v-tooltip="'Cadastro sem codigo do SOC'" class="pi pi-exclamation-circle"
                                style="font-size: 2rem; color: #ff9900; cursor: pointer" @click="logs(data)" />
                        </div>
                        <div v-if="data.cliente?.integracaoSoc && data.codigoSoc" class="field mt-1 mb-0"
                            style="text-align: center">
                            {{ data.codigoSoc }}
                        </div>
                    </template>
                </Column>
            </template>
            <template #expansion="slotProps">
                <div class="m-4">
                    <div class="grid">
                        <div class="col-4">
                            <strong class="block my-2">CNPJ/CPF/CEI/CAEPF/CNO</strong>
                            {{ getFormatacaoCnpj(slotProps.data.tradeCode) }}
                        </div>
                        <div class="col-4">
                            <strong class="block my-2">Inscrição Estadual</strong>
                            {{ slotProps.data.stateRegistration ? slotProps.data.stateRegistration : 'Não informado' }}
                        </div>
                        <div class="col-4">
                            <strong class="block my-2">Incrição Municipal</strong>
                            {{ slotProps.data.municipalRegistration ?
            slotProps.data.municipalRegistration : 'Não informado' }}
                        </div>
                    </div>
                    <Divider class="my-4" />
                    <div class="grid">
                        <div class="col-4">
                            <strong class="block my-2">CNAE</strong>
                            {{ slotProps.data.cnae ? slotProps.data.cnae.codigo + ' - ' + slotProps.data.cnae.descricao
            : 'Não informado' }}
                        </div>
                        <div class="col-4">
                            <strong class="block my-2">Grau de risco</strong>
                            {{ 'Grau: ' + slotProps.data.riskLevel + ' - ' +
            getMensagemConfomeGrauDeRisco(slotProps.data.riskLevel) }}
                        </div>
                        <div class="col-4">
                            <strong class="block my-2">Tipo de local</strong>
                            {{ getLabelTipoLocal(slotProps.data.tipoLocal) }}
                        </div>
                    </div>

                    <Divider class="my-4" />
                    <strong class="block my-2">Endereço</strong>
                    {{ slotProps.data.street }}, {{ slotProps.data.number }} - {{ slotProps.data.addressComplement }}
                    -
                    {{ slotProps.data.neighborhood }}, {{ slotProps.data.city.cidade }} / {{ slotProps.data.city.estado
                    }} -
                    {{ $filters.zipcode(slotProps.data.zipCode) }} <br />
                </div>
            </template>
        </AppPaginatedGrid>
        <AppInactivateDialog inactivatePath="/customer/branches/inativar/"
            customTitle="Esta unidade possui vínculo com hierarquia. O registro será apenas inativado. Deseja continuar?"
            v-if="showInactivateRecordDialog" :record="record" v-model:visible="showInactivateRecordDialog"
            @onConfirm="onClickInactivateRecord" @onClose="onClickCloseInactivateRecord">
        </AppInactivateDialog>
        <AppLogErroDialog v-if="showLogsDialog" v-model:visible="showLogsDialog" title="Logs para a unidade"
            origem="CADASTRO_UNIDADE" v-model:origemId="record.id" @onClose="showLogsDialog = false">
        </AppLogErroDialog>
    </div>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import TipoLocal from '../../enums/TipoLocal';
import { mCNPJ } from '../../utils/Mask';
import { getClientBase } from '../../services/http';

export default {
    data() {
        return {
            loadingEffect: true,
            record: {},
            tenantCustomers: [],
            service: null,
            mensagemValidadeExame: null,
            tipoLocais: [],
            showLogsDialog: false,
            labelTipoLocal: null,
            showInactivateRecordDialog: false,
            customTitleInactivateDialog: null
        };
    },
    mounted() {
        this.$service = new BaseService('/customer/branches');
        this.tenantCustomers = getCurrentCustomers();
        this.tipoLocais = Object.keys(TipoLocal).map(function (type) {
            return { label: `${TipoLocal[type]}`, value: +type };
        });
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    created() {
        this.service = new BaseService('/customer/branches');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
        async customDelete(record) {
            this.loadingEffect = false;
            this.$refs.grid.setLazyEffect();
            this.record = record;
            const { data } = await getClientBase().get('/customer/branches/verificar-hierarquia-vinculada/' + record.id);
            if (data.length) {
                this.$refs.grid.disableLazyEffect();
                this.showInactivateRecordDialog = true;
            } else {
                this.$refs.grid.disableLazyEffect();
                this.$refs.grid.openDeleteRecordDialog();
            }
        },
        async onClickInactivateRecord() {
            this.onClickCloseInactivateRecord();
            this.load();
        },
        onClickCloseInactivateRecord() {
            this.showInactivateRecordDialog = false;
        },
        getFormatacaoCnpj(cnpj) {
            return mCNPJ(cnpj);
        },
        getMensagemConfomeGrauDeRisco(grauRisco) {
            switch (grauRisco) {
                case '1':
                case '2':
                    this.mensagemValidadeExame = 'Validade para exames realizados em até 135 dias';
                    break;
                case '3':
                case '4':
                    this.mensagemValidadeExame = 'Validade para exames realizados em até 90 dias';
                    break;
                default:
                    break;
            }

            return this.mensagemValidadeExame;
        },
        getLabelTipoLocal(tipoLocal) {
            switch (tipoLocal) {
                case 1:
                    this.labelTipoLocal = 'Estabelecimento do empregador no Brasil';
                    break;
                case 2:
                    this.labelTipoLocal = 'Estabelecimento do empregador no exterior';
                    break;
                case 3:
                    this.labelTipoLocal = 'Estabelecimento de terceiros onde o empregador presta serviços';
                    break;
                case 4:
                    this.labelTipoLocal = 'Via pública';
                    break;
                case 5:
                    this.labelTipoLocal = 'Área rural';
                    break;
                case 6:
                    this.labelTipoLocal = 'Embarcação';
                    break;
                case 9:
                    this.labelTipoLocal = 'Outros';
                    break;
                default:
                    this.labelTipoLocal = 'Não informado';
                    break;
            }

            return this.labelTipoLocal;
        },
        async logs(data) {
            this.record = data;
            this.showLogsDialog = true;
        }
    }
};
</script>
